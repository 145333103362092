import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"

import Layout from '../components/Layout'
import SEO from '../components/seo'

const listing = {
  marginBottom: '1rem',
}

const Listing = styled.div`
  margin-bottom: 2rem;
`

const StyledLink = styled(Link)`
  color: #333;
  font-size: 2.6rem;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: #ff0000;
  }
`;

const Summary = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.4;
  margin-top: .5rem;
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`, `react`, `command line`]}
        />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Listing key={node.fields.slug}>
              <h3>
                <StyledLink to={node.fields.slug}>
                  {title}
                </StyledLink>
              </h3>
              <Summary dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </Listing>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } }
    }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
